.fa-sort-asc:before {
    content: "\f0de";
}

.fa-sort-desc:before {
    content: "\f0dd";
}

body {
    height: 100%
}

#root {
    min-height: 100% !important;
    height: 100vh !important;
}

.font-smaller {
    font-size: 0.8rem;
}

.font-small {
    font-size: 0.9rem;
}

.bg-table-gray:nth-of-type(even) {
    background: #eaeaea;
}

tr.custom_centered td {
    text-align: center;
    vertical-align: middle;
}

.form-control-xs {
    height: calc(1em + 0.5rem + 3px);
    padding: 0.2rem 0.2rem;
    font-size: 0.8rem;
    line-height: 1;
    border-radius: 0.2rem;
}

.btn-xs {
    height: calc(1em + 0.5rem + 3px);
    padding: 0.2rem 0.2rem;
    font-size: 0.8rem;
    line-height: 1;
    border-radius: 0.2rem;
}

.cursor-alias {
    cursor: alias;
}

.cursor-all-scroll {
    cursor: all-scroll;
}

.cursor-auto {
    cursor: auto;
}

.cursor-cell {
    cursor: cell;
}

.cursor-context-menu {
    cursor: context-menu;
}

.cursor-col-resize {
    cursor: col-resize;
}

.cursor-copy {
    cursor: copy;
}

.cursor-crosshair {
    cursor: crosshair;
}

.cursor-default {
    cursor: default;
}

.cursor-e-resize {
    cursor: e-resize;
}

.cursor-ew-resize {
    cursor: ew-resize;
}

.cursor-grab {
    cursor: -webkit-grab;
    cursor: grab;
}

.cursor-grabbing {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.cursor-help {
    cursor: help;
}

.cursor-move {
    cursor: move;
}

.cursor-n-resize {
    cursor: n-resize;
}

.cursor-ne-resize {
    cursor: ne-resize;
}

.cursor-nesw-resize {
    cursor: nesw-resize;
}

.cursor-ns-resize {
    cursor: ns-resize;
}

.cursor-nw-resize {
    cursor: nw-resize;
}

.cursor-nwse-resize {
    cursor: nwse-resize;
}

.cursor-no-drop {
    cursor: no-drop;
}

.cursor-none {
    cursor: none;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-progress {
    cursor: progress;
}

.cursor-row-resize {
    cursor: row-resize;
}

.cursor-s-resize {
    cursor: s-resize;
}

.cursor-se-resize {
    cursor: se-resize;
}

.cursor-sw-resize {
    cursor: sw-resize;
}

.cursor-text {
    cursor: text;
}

.cursor-w-resize {
    cursor: w-resize;
}

.cursor-wait {
    cursor: wait;
}

.cursor-zoom-in {
    cursor: zoom-in;
}

.cursor-zoom-out {
    cursor: zoom-out;
}

.slider-track-low {

}


.real-align-middle {
    vertical-align: middle !important;
    align-items: center !important;
    align-self: baseline !important;
}

.modal-backdrop-0 {
    z-index: 1000;
}

.modal-border-0 {
    z-index: 1001;
}

.modal-content-0 {
    z-index: 1002;
}

.modal-backdrop-1 {
    z-index: 2000;
}

.modal-border-1 {
    z-index: 2001;
}

.modal-content-1 {
    z-index: 2002;
}

.modal-backdrop-2 {
    z-index: 3000;
}

.modal-border-2 {
    z-index: 3001;
}

.modal-content-2 {
    z-index: 3002;
}