.from-text {
    position: absolute;
    bottom: 14px;
    left: 60px;
    font-size: 12px;
    color: #1a1a1a;
}

.to-text {
    position: absolute;
    bottom: 14px;
    left: 150px;
    font-size: 12px;
    color: #1a1a1a;
}
#hix-chart {
    width: 260px;
    height: 108px;
}